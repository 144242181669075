<template>

<app-wrapper class="content">

	<div class="content-bkg" v-bgimage="$state.page.banner" />

	<app-wrapper :content="true" :text="true" class="content-content">

		<div class="content-content-inner" v-html="$state.page.content" />

	</app-wrapper>

</app-wrapper>

</template>

<script>

export default {

}

</script>

<style scoped>

.content {
	overflow: hidden;
}

.content-bkg {
	position: fixed;
	right: 0px;
	height: 100vh;
	left: 50%;
	top: 0px;
	background-color: #eee;
	background-size: cover;
	background-position: 0px 0px;
	z-index: 1;
}

.is-tablet .content-bkg {
	left: 50%;
}

.is-mobile .content-bkg {
	width: 100%;
	display: block;
	height: 180px;
	position: relative;
	top: auto;
	left: auto;
	right: auto;
}

.content-content {
	z-index: 2;
}

.content-content {
	padding: 0px 360px 0px 0px;
}

.is-tablet .content-content {
	padding: 0px 150px 0px 0px;
}

.is-mobile .content-content {
	padding: 0px;
}

.content-content-inner {
	padding: 64px 0px;
	padding-right: 40px;
	background-color: #fff;
}

.is-tablet .content-content-inner {
	padding: 40px 0px;
	padding-right: 20px;
}

.is-mobile .content-content-inner {
	padding: 30px 0px;
	padding-right: 0px;
}

.content-content-inner >>> blockquote {
	width: calc(100% + 80px);
}

.is-tablet .content-content-inner >>> blockquote {
	width: calc(100% + 170px);
}

.is-mobile .content-content-inner >>> blockquote {
	width: 100%;
}

</style>
